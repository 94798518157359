import { Injectable, inject } from '@angular/core';
import { BackendService } from './backend.service';
import { UserService } from './user.service';
import { BehaviorSubject, map } from 'rxjs';
import { Portfolio, PortfolioBase } from '../types/types';

@Injectable({
    providedIn: 'root',
})
export class PortfolioListService {
    private backendService = inject(BackendService);
    private userService = inject(UserService);

    private list = new BehaviorSubject<Portfolio[]>([]);
    private pendingList = new BehaviorSubject<PortfolioBase[]>([]);
    public readonly list$ = this.list.asObservable();
    public readonly pendingList$ = this.pendingList.asObservable();
    public loadList() {
        const user = this.userService.getCurrent();
        if (!user.uri) {
            console.warn('User not logged in.');
            return;
        }
        this.backendService
            .get$<{
                portfolios: Portfolio[];
                pendingPortfolios: PortfolioBase[];
            }>('/portfolio-list/' + user.uri)
            .subscribe({
                next: (response) => {
                    this.list.next(response.body!.portfolios);
                    this.pendingList.next(response.body!.pendingPortfolios);
                },
                error: (error) => console.warn("Couldn't fetch portfolios. " + error.message),
            });
    }
    public getList() {
        const user = this.userService.getCurrent();
        return this.backendService
            .get$<{
                portfolios: Portfolio[];
                pendingPortfolios: PortfolioBase[];
            }>('/portfolio-list/' + user.uri)
            .pipe(map((response) => response.body!));
    }
    public reset() {
        this.list.next([]);
        this.pendingList.next([]);
    }
}
